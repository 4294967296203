<template>
  <div></div>
</template>

<script>
import { EventBus, Events } from '@events'
export default {
  name: 'AssessmentCreateView',

  methods: {
    onCancelCreate() {
      this.$router.push({ name: 'browse' })
    },
  },

  created() {
    EventBus.$on(Events.CREATE_ASSESSMENT_CANCEL, this.onCancelCreate)
  },

  beforeDestroy() {
    EventBus.$off(Events.CREATE_ASSESSMENT_CANCEL, this.onCancelCreate)
  },

  mounted() {
    EventBus.$emit(Events.CREATE_ASSESSMENT)
  },
}
</script>
